// a component to display the price of a token in USD from multiple sources
// this is a work in progress, and is not currently used in the app
import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { BackEndAPI } from "../../apis/backend";

//check if the element is null
function get_value(element) {
  if (element == null) {
    return "";
  } else {
    return element.value;
  }
}

const columns = [
  {
    headerName: "Exchange",
    field: "Exchange",
    sortable: false,
    editable: false,
    width: 120,
    pinnable: true,
  },
  {
    headerName: "Mkt Price",
    field: "Price",
    type: "number",
    width: 120,
    editable: false,
    sortable: true,
    valueFormatter: (params) => {
      const valueFormatted = Number((Math.round(params.value * 10000) / 10000).toFixed(4)).toLocaleString();
      return `${valueFormatted}`;
     }   
  },
  {
    headerName: "Exch Fees",
    field: "ExchangeFees",
    type: "number",
    width: 90,
    editable: false,
    align: "right",
    sortable: false,
    valueFormatter: (params) => {
      const valueFormatted = Number((Math.round(params.value * 10000) / 10000).toFixed(4)).toLocaleString();
      return `${valueFormatted}`;
     }   
  },
  {
    headerName: "Total w/Fees",
    field: "Total",
    type: "number",
    width: 130,
    editable: false,
    align: "right",
    sortable: true,
    valueFormatter: (params) => {
      const valueFormatted = Number((Math.round(params.value * 10000) / 10000).toFixed(4)).toLocaleString();
      return `${valueFormatted}`;
     }   
  },
  {
    headerName: "Volume24h",
    field: "Volume24h",
    type: "number",
    width: 120,
    editable: false,
    sortable: true,
    valueFormatter: (params) => {
      const valueFormatted = Number((Math.round(params.value * 10000) / 10000).toFixed(4)).toLocaleString();
      return `${valueFormatted}`;
     }   
  },
  {
    headerName: "VolumeHour",
    field: "VolumeHour",
    type: "float",
    width: 120,
    editable: false,
    align: "right",
    sortable: true,
    valueFormatter: (params) => {
      const valueFormatted = Number((Math.round(params.value * 10000) / 10000).toFixed(4)).toLocaleString();
      return `${valueFormatted}`;
     }   
  }
  ,
  { headerName: "ID", field: "id", hide: true },
];

const exchanges = [
    "Coinbase",
    "Gemini",
    "Bitstamp",
    "Kraken",
    "Bitfinex",
    "Binance",
    "Bittrex",
    "OKEx",
    "KuCoin",
    "BitMEX",
    "Bitso",
    "Bithumb", 
    "BitPanda",
    "CEX.IO",
    "Gate.io"
  ];

export default function PriceAggregatorContainer() {
  const [data, setData] = useState([]);
  
  const handleClick = (e) => {
    var from_currency = get_value(
      document.getElementById("FromCurrencyTextField")
    );
    if (from_currency === "") {
      from_currency = "BTC";
    }

    var to_currency = get_value(document.getElementById("ToCurrencyTextField"));
    if (to_currency === "") {
      to_currency = "USD";
    }

    var amount = get_value(document.getElementById("AmountTextField"));
    
    if (amount === "") {
      amount = 1;
    }
    if (isNaN(amount)) {
      amount = 1;
    }
    if (amount < 0) {
      amount = amount * -1;
    }
    
    BackEndAPI.getExchangeDataFull(from_currency, to_currency, amount, exchanges).then((data) => {
        setData(JSON.parse(data))
    })
  };

  const [sortModel, setSortModel] = React.useState([
    {
      field: "Price",
      sort: "asc",
    },
  ]);

  return (
    <div>
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="md">
          <Box sx={{ height: "auto" }}>
        
            <Stack spacing={1} height={20} direction="row">
          
          <Typography variant="h1" component="h2">
                {" "}
                Amount{" "}
              </Typography>
              <TextField
                id="AmountTextField"
                label=""
                variant="standard"
                defaultValue={1}
                height={20}
                type="number"
              />
              <Typography variant="h1" component="h2">
                {" "}
                From{" "}
              </Typography>
              <TextField
                id="FromCurrencyTextField"
                label=""
                variant="standard"
                defaultValue={"BTC"}
                height={20}
              />
              <Typography variant="h1" component="h2">
                {" "}
                To{" "}
              </Typography>
              <TextField
                id="ToCurrencyTextField"
                label=""
                variant="standard"
                defaultValue={"USD"}
              />
              <Button
                id="GetBestPricesButton"
                variant="contained"
                onClick={handleClick}
                type="submit"
              >
                Update
              </Button>
             
            </Stack>
            <br />
            <br />
            <DataGrid
              rows={data}
              columns={columns}
              rowsPerPageOptions={[50]}
              disableCheckboxSelection
              disableSelectionOnClick
              experimentalFeatures={{ newEditingApi: true }}
              sortModel={sortModel}
              autoHeight={true}
              autoWidth={true}
              onSortModelChange={(model) => setSortModel(model)}
            />
          </Box>
       <br/>
          <Box sx={{ height: "50vh" }}>
            <Typography variant="h1" component="h5" autoWidth={true}>
              
        <b>Exchange Fees: </b>Market Prices are obtained using a default value of 1 unit of the base currency. Fees are calculated using the amount of the base currency. 
        For example, if you are trading 1 BTC for 10,000 USD, the fee will be calculated using 1 BTC. If you are trading 0.1 BTC for 1,000 USD, the fee will be calculated using 0.1 BTC.
        The exchange fee (maker and taker) rates are updated frequently and depend on the volume traded in the previous 30 days. Please check with your exchange for up to date fees schedule before trading.
        All prices are for informational purposes only and are not intended for trading. The prices are not guaranteed to be accurate and are subject to change without notice.
        <br/>
        <b>On Ramp Fees:</b> Most exchanges offer on ramping services to convert Fiat currency to crypto. These fees are not included in the exchange fees.
        <br/>
        <b>Gas Fees: </b> Gas fees vary throughout the day. This fees estimate will be available soon. 

    </Typography></Box></Container>
    
      </React.Fragment>

    </div>
  );
}
