import "./App.css";
import { Analytics } from '@vercel/analytics/react';
import MainAppWithDrawer from "./layouts/MainAppWithDrawer";
import customTheme from "./style/theme"
import { ThemeProvider } from '@mui/material/styles';
import { useAuth0 } from "@auth0/auth0-react";
import { Routes, Route } from "react-router-dom";
import PriceComparison from "./pages/PriceComparison";
import Test from "./pages/Test";
import Wallets from "./pages/Wallets";
import Trading from "./pages/Trading";
import Settings from "./pages/Settings";
import Dashboard from "./pages/Dashboard";
import Knowledge from "./pages/Knowledge";

function App() {
  
  const {isAuthenticated } = useAuth0()
  return (
        
    <ThemeProvider theme={customTheme}>
      
     
        <div>
          <MainAppWithDrawer/>
          
      {isAuthenticated && (            
        <div>
          <Routes>
            <Route exact path="/" element={<Dashboard/>}/>
            <Route exact path="/Dashboard" element={<div><Dashboard/></div>}/>
            <Route exact path="/PriceComparison" element={<div><PriceComparison/></div>}/>
            <Route exact path="/Wallets" element={<div><Wallets/></div>}/>
            <Route exact path="/Trading" element={<div><Trading/></div>}/>
            <Route exact path="/Settings" element={<div><Settings/></div>}/>
            <Route exact path="/Knowledge" element={<div><Knowledge/></div>}/>
            <Route exact path="/Test" element={<div><Test/></div>}/>
          </Routes>
          </div>)}
          <Analytics />
       </div>

    </ThemeProvider>
  );
}

export default App;
