import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { CodeSnippet } from "../components/code-snippets";

export default function Settings() {
    const { user } = useAuth0();

    if (!user) {
      return null;
    }
    return (
    <div>
        <p>Welcome to Settings. This page is under construction.</p>
        {/* <div className="profile-grid">
            <div className="profile__header">
              <img
                src={user.picture}
                alt="Profile"
                className="profile__avatar"
              />
              <div className="profile__headline">
                <h2 className="profile__title">{user.name}</h2>
                <span className="profile__description">{user.email}</span>
              </div>
            </div>
            <div className="profile__details">
              <CodeSnippet
                title="Decoded ID Token"
                code={JSON.stringify(user, null, 2)}
              /> */}
              {/* write snippet to extract user sub */}
                <CodeSnippet 
                    title="User sub"
                    code={user.sub}
                />
            {/* </div>
          </div>     */}
    </div>
    
    )
}
