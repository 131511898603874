import React from 'react';

export default function Wallets() {

    return (
    <div>
        <p>Welcome to Wallets. This page is under construction.</p>
        
    </div>
    
    )
}
