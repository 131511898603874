import { callExternalApi } from "./external-api.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getPublicResource = async () => {
  const config = {
    url: `${apiServerUrl}/api/messages/public`,
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};



export const getAdminResource = async (accessToken) => {
  const config = {
    url: `${apiServerUrl}/api/messages/admin`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};


export const getAccounts = async (user_sub, accessToken) => {
  const config = {
    url: `${apiServerUrl}/account?user_sub=${user_sub}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getExchangePrices = async (fromSym,toSym,exchanges, accessToken) => {
  const config = {
    url: `${apiServerUrl}/quote/best?from_currency=${fromSym}&to_currency=${toSym}&exchanges=${exchanges}`,
    method: "GET",
    headers: {
      "content-type": "application/json",
      Authorization: `Bearer ${accessToken}`,
      "X-API-SERVER": "85499f9f",
      "Access-Control-Allow-Origin": "127.0.0.1:3000",
      "Access-Control-Allow-Credentials": "true",
    },
  };

  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
};

export const getExchangeDataFull = async (fromSym,toSym,amount, exchanges, accessToken) => {
  
    const config = {
      url: `${apiServerUrl}/quote/prices?from_currency=${fromSym}&to_currency=${toSym}&amount=${amount}&exchanges=${exchanges}`,
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
        "X-API-SERVER": "85499f9f",
        "Access-Control-Allow-Origin": "127.0.0.1:3000",
        "Access-Control-Allow-Credentials": "true",
      },
  }
  const { data, error } = await callExternalApi({ config });

  return {
    data: data || null,
    error,
  };
}

