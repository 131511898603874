import {red } from '@mui/material/colors';
import { createTheme } from "@mui/material/styles";

const myTheme = createTheme({
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: red,
      },
    },
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ef9e34e5',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      main: '#ef9e34e5',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    }
  },
  typography: {
     fontFamily: "Nunito Sans, -apple-system, BlinkMacSystemFont, 'avenir next', avenir, helvetica, 'helvetica neue', ubuntu, roboto, noto, 'segoe ui', arial, sans-serif;",
     fontSize: "18px",
     fontWeightBold: 700,
     fontWeightRegular: 400
  }
});

export default createTheme(myTheme);
