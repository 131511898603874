import React, { useEffect, useState } from "react";
import { CodeSnippet } from "../components/code-snippets";
import { useAuth0 } from "@auth0/auth0-react";
import { getAccounts } from "../apis/message.service";

export default function Dashboard() {
  const [message, setMessage] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const { user } = useAuth0();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    let isMounted = true;

    const getMessage = async () => {
      const accessToken = await getAccessTokenSilently();
      const user_sub = user.sub.split("|")[1];
      // const user_name = user.name;

      const { data, error } = await getAccounts(user_sub, accessToken);

      //   set access token
      if (accessToken) {
        setAccessToken(accessToken);
      }

      if (!isMounted) {
        return;
      }
      if (data) {
        setMessage(JSON.stringify(data, null, 2));
      }

      if (error) {
        setMessage(JSON.stringify(error, null, 2));
      }
    };

    getMessage();

    return () => {
      isMounted = false;
    };
  }, [getAccessTokenSilently, user]);

  return (
    <div>
      <p>Welcome to your Dashboard. This page is under construction</p>
      {/* <CodeSnippet title="Server Response" code={message} /> */}
    </div>
  );
}
