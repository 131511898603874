
import {api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const BackEndAPI = {

    getExchangePrices: async function (fromSym,toSym,exchanges, cancel = false) {
        
        const response = await api.request({
            url: `/quote/best?from_currency=${fromSym}&to_currency=${toSym}&exchanges=${exchanges}`,
            method: "GET",
            headers: {
                        Accept: "application/json",
                        "Content-Type": " application/json",
                        "X-API-SERVER": "85499f9f",
                        "Access-Control-Allow-Origin": "127.0.0.1:3000",
                        "Access-Control-Allow-Credentials": "true",
                      },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        
        return response.data
    }
    ,

    
    getExchangeDataFull: async function (fromSym,toSym,amount, exchanges, cancel = false) {
        
        const response = await api.request({
            url: `/quote/prices?from_currency=${fromSym}&to_currency=${toSym}&amount=${amount}&exchanges=${exchanges}`,
            method: "GET",
            headers: {
                        Accept: "application/json",
                        "Content-Type": " application/json",
                        "X-API-SERVER": "85499f9f",
                        "Access-Control-Allow-Origin": "127.0.0.1:3000",
                        "Access-Control-Allow-Credentials": "true",
                      },
            signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        })
        
        return response.data
    },

}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(BackEndAPI)