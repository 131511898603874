import React, { useEffect, useRef } from 'react';

export default function Knowledge() {

    return (
    <div>
        <p>Welcome to Knowledge. This page is under construction.</p>
        
    </div>
    
    )
}
