import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WalletIcon from '@mui/icons-material/Wallet';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import SettingsIcon from '@mui/icons-material/Settings';
import SchoolIcon from '@mui/icons-material/School';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import { useAuth0 } from "@auth0/auth0-react";
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Link } from "react-router-dom";
import Container from "@mui/material/Container"
import Drawer from '@mui/material/Drawer';
const drawerWidth = 200;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);


const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 10),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const LoginButton = () => {
  const { loginWithRedirect,isAuthenticated } = useAuth0();
  return (!isAuthenticated && (<div><Button onClick={() => loginWithRedirect()}>Log In</Button></div>));
};


export default function MiniDrawer() {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { logout } = useAuth0();

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    
    logout({ logoutParams: { returnTo: "https://www.luloinvest.com" } })
    auth = !auth
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { user, isAuthenticated } = useAuth0();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} style={{ background: "#FFFFFF" }}>
        <Toolbar>
        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Welcome to Lulo Invest
          </Typography>
          
          <LoginButton ></LoginButton>
          
        {isAuthenticated && (
        <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                // onMouseEnter={ProfileOverView}
                color="primary"

              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                            
                <img src={user.picture} height="50" width="50" alt={user.name} /> 
                {/* <h4>{user.name}</h2> */}
                {/* <p>{user.email}</p>  */}
                <MenuItem onClick={handleLogout}>Log Out</MenuItem>
              </Menu>
            </div>
      )}
          </Toolbar>
      </AppBar>
      <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon img=".././static/images/logo.svg" />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            
          </Typography>
        </Toolbar>
        {!isAuthenticated && (<div>
          <Drawer  sx={{
              width: 100,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: 100,
                boxSizing: 'border-box',
              },
            }} variant="persistent" anchor="left" open={open}>
            <DrawerHeader>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </DrawerHeader>
            </Drawer>
            </div>
        )}
      {isAuthenticated && (<div>
      <Drawer  sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }} variant="persistent" anchor="left" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {['Dashboard', 'PriceComparison', 'Wallets', 'Trading', 'Settings', 'Knowledge'].map((text, index) => (
            
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
              <ListItemButton sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
                href={text}
                component={Link}
                to={`/${text}`}
                variant="contained"
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                 {
                   index === 0 ? <DashboardIcon/> : index === 1 ? <PriceChangeIcon/> : index === 2? <WalletIcon/> : index === 3? <ShowChartIcon/>
                   : index === 4? <SettingsIcon/> : <SchoolIcon/>
                 }
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      </div>)}
      <Main open={open}>
      <Container  sx={{
                  minHeight: 48,
                  justifyContent: 'center',
                  px: 2.5,
                }}>
      <Box component="main" sx={{ flexGrow: 3, p: 3, justifyContent: 'center', px: 2.5, }}>
      
      </Box>
      </Container> 
      </Main>
      {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
 
       {isAuthenticated && (<div>
        <div>
          <BasicTable theme={customTheme}></BasicTable>
        </div>
        <br />
        <div>
          <Button variant="contained" theme={customTheme}>Refresh</Button>
        </div>
        </div>)}
 
      </Box> */}
    </Box>
  );
}